<template>
  <div>
    <form-wizard
      color="#382DB8"
      :title="null"
      :subtitle="null"
      shape="circle"
      next-button-text="Suivant"
      :finish-button-text="
        isRegisterProLoading ? 'Chargement...' : 'Enregistrer'
      "
      back-button-text="Retour"
      class="mb-3"
      @on-complete="applyRegisterProfessionalAction"
    >
      <!-- accoint details tab -->
      <tab-content
        title="Identité du pro"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <small class="text-muted font-small-4">
                Renseignez les informations relatives à l'identité du pro
              </small>
            </b-col>

            <b-col
              v-if="getUser.role.name == 'Business developer Pro'"
              md="6"
            >
              <b-form-group
                label="Code du BDP"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                >
                  <b-form-input
                    id="referral_code"
                    v-model="payload.referral_code"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="BDP-XXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Photos de profile du pro"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="photo"
                  rules="required"
                >
                  <b-form-file
                    id="wildcard"
                    v-model="payload.profile_image"
                    accept="image/*"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Lieu"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="lieu"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="payload.address"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez l'adresse du pro"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Prénom & Nom"
                label-for="full-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name=" prenom & nom "
                  rules="required"
                >
                  <b-form-input
                    id="full-name"
                    v-model="payload.full_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le prenom suivi du nom du pro"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Numéro de téléphone"
                rules="required"
              >
                <b-form-group
                  label="Numéro de téléphone"
                  label-for="phone"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      is-text
                      :class="errors[0] ? 'border-danger' : ''"
                    >
                      BJ (+229)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="phone"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                      placeholder="12 34 56 78"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Adresse email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="email"
                >
                  <b-form-input
                    id="email"
                    v-model="payload.email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez l'adresse email du pro"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- personal details tab -->
      <tab-content
        title="Service(s) & Réalisations"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <small
                class="text-muted font-small-4"
              >Renseignez les services fournies par ce pro</small>
            </b-col>
          </b-row>
          <b-row
            v-for="(form, index) in forms"
            :key="index"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="services"
                rules="required"
              >
                <b-form-group
                  label="Services"
                  label-for="services"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="role"
                    v-model="form.service_id"
                    :reduce="(service) => service.id"
                    label="name"
                    :options="servicesOptions"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="réalisation"
                rules=""
              >
                <b-form-group
                  label="Réalisation"
                  label-for="works-picture"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-file
                    v-model="form.works_picture"
                    placeholder="Choisissez les réalisations des pros"
                    multiple
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group
                  label="Description"
                  label-for="description"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Description"
                    rows="3"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              offset-md="3"
              class="mt-4"
            >
              <b-button
                variant="danger"
                :disabled="forms.length == 1"
                @click="removeItem(index)"
              >
                Supprimer
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-4">
            <b-col md="6">
              <b-button
                variant="primary"
                :disabled="isLoading"
                @click="repeateAgain"
              >
                Ajouter un autre service
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  // BFormSelect,
  BCol,
  BFormGroup,
  // BOverlay,
  // BProgress,
  BButton,
  BFormTextarea,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BFormFile,
} from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bj'
import { required, email, beninPhoneNumberFormatRule } from '@validations'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider,
    // BOverlay,
    // BProgress,
    // BFormSelect,
    vSelect,
    BFormFile,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Cleave,
    vue2Dropzone,
  },
  data() {
    return {
      forms: [
        {
          service_id: '',
          description: '',
          works_picture: [],
        },
      ],
      payload: {
        profile_image: '',
        full_name: '',
        address: '',
        services_id: [],
        descriptions: [],
        works_picture: [],
        email: '',
        phone_number: '',
        referral_code: '',
      },
      phone: '',

      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'BJ',
        },
      },
      busy: false,
      service_id: '',
      works_picture: [],
      description: '',
      isRegisterProLoading: false,
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      required,
      email,
      beninPhoneNumberFormatRule,
    }
  },
  computed: {
    ...mapGetters('services', ['getServices']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('services', ['getServicesWithoutPagination']),

    phoneNumber() {
      return this.phone.replace(/\s/g, '')
    },

    servicesOptions() {
      const data = []
      this.getServicesWithoutPagination.forEach(element => {
        data.push({
          id: element.id,
          name: element.name,
        })
      })
      return data
    },
  },
  mounted() {
    this.configProAddressField()
  },
  methods: {
    ...mapActions('professionals', [
      'registerProfessionalAction',
      'getProfessionalsAction',
    ]),
    configProAddressField() {
      const input = document.getElementById('address')
      const options = {
        types: ['establishment'],
        componentRestrictions: { country: ['BJ'] },
      }
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input, options)

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace()
        this.payload.address = place.name
      })
    },
    repeateAgain() {
      this.forms.push({
        service_id: '',
        description: '',
        works_picture: [],
      })
    },
    removeItem(index) {
      this.forms.splice(index, 1)
    },
    applyRegisterProfessionalAction() {
      this.isRegisterProLoading = true
      this.forms.forEach(element => {
        this.payload.services_id.push(element.service_id)
        this.payload.descriptions.push(element.description)
        this.payload.works_picture.push(element.works_picture)
      })

      console.log(this.payload)
      this.payload.phone_number = `229${this.phoneNumber}`
      this.registerProfessionalAction(this.payload)
        .then(() => {
          this.getProfessionalsAction().then(() => {
            this.isRegisterProLoading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Pro enregistré',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$router.push({ name: 'admin-work-providers-professionals' })
            this.payload = {
              profile_image: '',
              full_name: '',
              address: '',
              services_id: [],
              descriptions: [],
              email: '',
              phone_number: '',
            }
          })
        })
        .catch(error => {
          this.isRegisterProLoading = false
          this.payload.phone_number = ''
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
