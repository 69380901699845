var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#382DB8","title":null,"subtitle":null,"shape":"circle","next-button-text":"Suivant","finish-button-text":_vm.isRegisterProLoading ? 'Chargement...' : 'Enregistrer',"back-button-text":"Retour"},on:{"on-complete":_vm.applyRegisterProfessionalAction}},[_c('tab-content',{attrs:{"title":"Identité du pro","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('small',{staticClass:"text-muted font-small-4"},[_vm._v(" Renseignez les informations relatives à l'identité du pro ")])]),(_vm.getUser.role.name == 'Business developer Pro')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Code du BDP","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"referral_code","type":"text","state":errors.length > 0 ? false : null,"placeholder":"BDP-XXXX"},model:{value:(_vm.payload.referral_code),callback:function ($$v) {_vm.$set(_vm.payload, "referral_code", $$v)},expression:"payload.referral_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4127099392)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Photos de profile du pro","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"photo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"wildcard","accept":"image/*"},model:{value:(_vm.payload.profile_image),callback:function ($$v) {_vm.$set(_vm.payload, "profile_image", $$v)},expression:"payload.profile_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Lieu","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"lieu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'adresse du pro","autocomplete":"off"},model:{value:(_vm.payload.address),callback:function ($$v) {_vm.$set(_vm.payload, "address", $$v)},expression:"payload.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prénom & Nom","label-for":"full-name"}},[_c('validation-provider',{attrs:{"name":" prenom & nom ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full-name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez le prenom suivi du nom du pro"},model:{value:(_vm.payload.full_name),callback:function ($$v) {_vm.$set(_vm.payload, "full_name", $$v)},expression:"payload.full_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro de téléphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de téléphone","label-for":"phone"}},[_c('b-input-group',[_c('b-input-group-prepend',{class:errors[0] ? 'border-danger' : '',attrs:{"is-text":""}},[_vm._v(" BJ (+229) ")]),_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"placeholder":"12 34 56 78"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'adresse email du pro"},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Service(s) & Réalisations","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('small',{staticClass:"text-muted font-small-4"},[_vm._v("Renseignez les services fournies par ce pro")])])],1),_vm._l((_vm.forms),function(form,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"services","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Services","label-for":"services","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"role","reduce":function (service) { return service.id; },"label":"name","options":_vm.servicesOptions},model:{value:(form.service_id),callback:function ($$v) {_vm.$set(form, "service_id", $$v)},expression:"form.service_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"réalisation","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Réalisation","label-for":"works-picture","state":errors.length > 0 ? false : null}},[_c('b-form-file',{attrs:{"placeholder":"Choisissez les réalisations des pros","multiple":""},model:{value:(form.works_picture),callback:function ($$v) {_vm.$set(form, "works_picture", $$v)},expression:"form.works_picture"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","label-for":"description","state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Description","rows":"3"},model:{value:(form.description),callback:function ($$v) {_vm.$set(form, "description", $$v)},expression:"form.description"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"md":"3","offset-md":"3"}},[_c('b-button',{attrs:{"variant":"danger","disabled":_vm.forms.length == 1},on:{"click":function($event){return _vm.removeItem(index)}}},[_vm._v(" Supprimer ")])],1)],1)}),_c('b-row',{staticClass:"mt-4 mb-4"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":_vm.repeateAgain}},[_vm._v(" Ajouter un autre service ")])],1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }